import React, { Fragment, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Select from "react-select";
import DayjsUtils from "@date-io/dayjs";
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import Layout from "../components/Layout";
import { HTMLContent } from "../components/Content";
import {
  MakeRequired,
  getSkillsList,
  useScript,
  HtmlString2Component,
} from "../utils/utils";
import { db, storageRef, firebaseFunc } from "../components/firebase/firestore";
import { AuthContext } from "../components/auth/Auth";
import { occasioMaterialTheme } from "../components/mui/muiUtils";

const submitForm = ({
  idPrefix,
  currentUser,
  file,
  logoFile,
  postSubmit,
  customFields,
}) => event => {
  event.preventDefault();

  const button = document.getElementById(`${idPrefix}-btn`);
  button.classList.add("is-loading");

  const data = [
    ...document.querySelectorAll(
      `#${event.target.id} input[type=text], #${event.target.id} input[type=number], #${event.target.id} input[type=checkbox], #${event.target.id} input[type=radio], #${event.target.id} select, #${event.target.id} input[type=tel], #${event.target.id} input[type=email], #${event.target.id} input[type=hidden], #${event.target.id} textarea`,
    ),
  ]
    .filter(e => e.name)
    .reduce((accumulator, currentValue) => {
      if (currentValue.type === "radio") {
        if (currentValue.checked) {
          accumulator[currentValue.name] = currentValue.value;
        }
      } else if (accumulator.hasOwnProperty(currentValue.name)) {
        if (Array.isArray(accumulator[currentValue.name])) {
          accumulator[currentValue.name].push(currentValue.value);
        } else {
          accumulator[currentValue.name] = [
            accumulator[currentValue.name],
            currentValue.value,
          ];
        }
      } else if (currentValue.type === "checkbox") {
        accumulator[currentValue.name] = currentValue.checked;
      } else {
        accumulator[currentValue.name] = currentValue.value;
      }
      return accumulator;
    }, {});

  if (currentUser) {
    data.uid = currentUser.uid;
  }

  if (file && file.size) {
    data.file_name = file.name;
  }
  if (logoFile && logoFile.size) {
    data.logo_name = "logo";
  }

  data.time = new Date();

  if (idPrefix === "money") {
    data.payment_successful = false;
    data.donation_amount = customFields.donationAmount;
  }

  db.collection(`donate_${idPrefix}`)
    .add(data)
    .then(async docRef => {
      if (file && file.size) {
        const cvRef = storageRef.child(
          `donate_${idPrefix}/${docRef.id}/${file.name}`,
        );
        await cvRef.put(file);
      }
      if (logoFile && logoFile.size) {
        const cvRef = storageRef.child(`donate_${idPrefix}/${docRef.id}/logo`);
        await cvRef.put(logoFile);
      }

      if (postSubmit) {
        await postSubmit(docRef.id, data.email, data.donation_type);
      }

      document.getElementById(`${idPrefix}-success`).hidden = false;
      document.getElementById(`${idPrefix}-form`).hidden = true;
    })
    .catch(error => {
      alert(`Error submitting data. ${error.message}`);
    })
    .finally(() => button.classList.remove("is-loading"));
};

const handleFileSelect = setFile => event => {
  const fileTemp = event.target.files[0];
  if (fileTemp && fileTemp.size) {
    if (fileTemp.size > 5000000) {
      alert("Please select file smaller than 5 Mb");
      return;
    }

    setFile(fileTemp);
  }
};

const handleImgFileSelect = setFile => event => {
  const fileTemp = event.target.files[0];
  if (fileTemp && fileTemp.size) {
    if (fileTemp.size > 5000000) {
      alert("Please select file smaller than 5 Mb");
      return;
    }

    if (!["image/png", "image/jpeg"].includes(fileTemp.type)) {
      alert("Wrong file type. Only JPG and PNG files are supported.");
      return;
    }

    setFile(fileTemp);
  }
};

const Success = ({ idPrefix }) => (
  <span className="control" id={`${idPrefix}-success`} hidden>
    <div className="columns">
      <div className="column">
        <span className="control">
          <div className="field has-text-centered m-t-xxl m-b-xxl is-size-5 has-text-primary">
            <div>
              Thank you for your submission! We will review and be in touch
              shortly.
            </div>
            <br />
            <div>
              <button
                type="button"
                className="button is-light"
                onClick={() => {
                  document.getElementById(`${idPrefix}-success`).hidden = true;
                  document.getElementById(`${idPrefix}-form`).hidden = false;
                }}
              >
                Send another submission
              </button>
            </div>
          </div>
        </span>
      </div>
    </div>
  </span>
);

const FormCol2 = () => (
  <>
    <div className="field">
      <label htmlFor="" className="label">
        First name
        <span className="has-text-danger"> *</span>
      </label>
      <div className="control">
        <input
          name="first_name"
          type="text"
          placeholder="Jane"
          className="input"
          autoComplete="given-name"
          required
        />
      </div>
    </div>
    <div className="field">
      <label htmlFor="" className="label">
        Surname
        <span className="has-text-danger"> *</span>
      </label>
      <div className="control">
        <input
          name="surname"
          type="text"
          placeholder="Smith"
          className="input"
          autoComplete="family-name"
          required
        />
      </div>
    </div>
    <div className="field">
      <label htmlFor="" className="label">
        Phone number
        <span className="has-text-danger"> *</span>
      </label>
      <div className="field is-marginless">
        <div className="field has-addons is-marginless">
          <div className="control has-icons-left is-expanded">
            <input
              id="phone-field"
              type="tel"
              placeholder="+18001234567"
              className="input"
              name="phone"
              autoComplete="tel"
              required
            />
            <span className="icon is-small is-left">
              <i className="fa fa-phone" />
            </span>
          </div>
          <div className="control" />
        </div>
        <p className="help">
          <i>format: +18001234567</i>
        </p>
      </div>
    </div>
    <label htmlFor="" className="label">
      Email
      <span className="has-text-danger"> *</span>
    </label>
    <div className="field">
      <div className="field has-addons ">
        <div className="control has-icons-left is-expanded">
          <input
            id="email-field"
            type="email"
            placeholder="e.g. jonhdoe@gmail.com"
            className="input"
            name="email"
            autoComplete="email"
            required
          />
          <span className="icon is-small is-left">
            <i className="fa fa-envelope" />
          </span>
        </div>
        <div className="control" />
      </div>
    </div>
  </>
);

const FormCol3 = ({ idPrefix, logoFile, setLogoFile }) => (
  <>
    <div className="field">
      <label htmlFor="" className="label">
        Institution/Organisations name
      </label>
      <div className="control">
        <input
          name="organization"
          type="text"
          placeholder="Corporation inc."
          className="input"
        />
      </div>
    </div>
    <div className="field">
      <label htmlFor="" className="label">
        Personal message
        <span className="has-text-danger"> *</span>
      </label>
      <div className="control">
        <textarea
          style={{
            minHeight: "8.5rem",
          }}
          className="textarea"
          name="message"
          required
        />
      </div>
    </div>
    <div className="field">
      <label htmlFor="" className="label">
        Personal image or logo
      </label>
      <div className="control">
        <div className="file has-name is-fullwidth is-success">
          <label className="file-label">
            <input
              className="file-input"
              type="file"
              accept=".jpg, .jpeg, .png"
              name="logo"
              onChange={handleImgFileSelect(setLogoFile)}
            />
            <span className="file-cta">
              <span className="file-icon">
                <i className="fas fa-upload" />
              </span>
              <span className="file-label">Choose a file</span>
            </span>
            <span className="file-name">{logoFile.name}</span>
          </label>
        </div>
        <p className="help is-italic">JPG and PNG only, maximum size is 5 Mb</p>
      </div>
    </div>
    <div className="field">
      <label htmlFor="" className="label">
        Publish donation
        <span className="has-text-danger"> *</span>
      </label>
      <div className="control">
        <input
          id={`${idPrefix}-publish`}
          type="checkbox"
          className="is-checkradio"
          name="publish"
          // defaultChecked
        />
        <label htmlFor={`${idPrefix}-publish`}>Make my donation public</label>
      </div>
    </div>
  </>
);

const Money = ({
  chooserMoneyInfo,
  donationAmount,
  setDonationAmount,
  currentUser,
}) => {
  const [logoFile, setLogoFile] = useState({ name: "" });
  const postSubmit = async (id, email, donationType) => {
    const donateMoney = firebaseFunc.httpsCallable("donateMoney");
    const donationAmountNum = Number(donationAmount);

    await donateMoney({
      donationAmount: donationAmountNum,
      email,
      id,
      donationType,
    })
      .then(async ({ data: resData }) => {
        if (resData) {
          const stripe = Stripe("pk_live_LT1TS0yoQXPrTPIHf7w6w5Rr00GQaUkTfc");
          const { error } = await stripe.redirectToCheckout({
            sessionId: resData.id,
          });
          alert(`Can't finish the payment. ${error.message}`);
        }
      })
      .catch(error => {
        throw error;
      })
      .finally(() => {});
  };

  return (
    <>
      <div className="m-b-lg md-in-ul">
        <HtmlString2Component str={chooserMoneyInfo} />
      </div>
      <div className="columns">
        <div className="column">
          <div className="panel">
            <div className="panel-heading" id="spread">
              Submit donation
            </div>

            <div className="panel-block">
              <Success idPrefix="money" />
              <form
                id="money-form"
                action=""
                className="control"
                onSubmit={submitForm({
                  idPrefix: "money",
                  currentUser,
                  logoFile,
                  postSubmit,
                  customFields: { donationAmount },
                })}
              >
                <span className="control">
                  <div className="columns">
                    <div className="column is-4">
                      <span className="control">
                        <div className="field">
                          <label htmlFor="" className="label">
                            Payment amount
                            <span className="has-text-danger"> *</span>
                          </label>
                          <div className="field has-addons flex-fullwidth">
                            {[25, 50, 100, 250].map(amount => (
                              <div className="control" key={amount}>
                                <button
                                  id={`money-${amount}`}
                                  type="button"
                                  className="button money-button"
                                  style={{
                                    width: "100%",
                                  }}
                                  onClick={() => {
                                    document
                                      .querySelectorAll(".money-button")
                                      .forEach(btn => {
                                        btn.classList.remove("is-primary");
                                      });
                                    document
                                      .getElementById("money-custom")
                                      .classList.remove("is-static-primary");
                                    document
                                      .getElementById("money-custom-input")
                                      .classList.remove("is-primary");
                                    document.getElementById(
                                      "money-custom-input",
                                    ).value = "";
                                    document
                                      .getElementById(`money-${amount}`)
                                      .classList.add("is-primary");
                                    setDonationAmount(amount);
                                  }}
                                >
                                  <span>${amount}</span>
                                </button>
                              </div>
                            ))}
                          </div>
                          <div className="field has-addons is-marginless">
                            <div className="control is-expanded">
                              <div className="field has-addons m-b-none">
                                <p className="control m-b-none">
                                  <a
                                    id="money-custom"
                                    className="button is-static"
                                    style={{
                                      border: "1px solid transparent",
                                    }}
                                  >
                                    $
                                  </a>
                                </p>
                                <p className="control is-expanded m-b-none">
                                  <input
                                    id="money-custom-input"
                                    className="input"
                                    type="number"
                                    min="5"
                                    placeholder="Enter custom amount"
                                    onChange={val => {
                                      document
                                        .querySelectorAll(".money-button")
                                        .forEach(btn => {
                                          btn.classList.remove("is-primary");
                                        });
                                      document
                                        .getElementById("money-custom")
                                        .classList.add("is-static-primary");
                                      document
                                        .getElementById("money-custom-input")
                                        .classList.add("is-primary");
                                      setDonationAmount(val.target.value);
                                    }}
                                  />
                                </p>
                              </div>
                              <MakeRequired
                                name="money_amount"
                                required={!donationAmount}
                              />
                              <p className="help m-b-none">
                                <i>minimum amount is $5</i>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="field">
                          <label htmlFor="" className="label">
                            Type
                            <span className="has-text-danger"> *</span>
                          </label>
                          <div className="control is-flex flex-evenly radio-vertical">
                            <div className="field">
                              <input
                                className="is-checkradio"
                                id="money-basis1"
                                type="radio"
                                name="donation_type"
                                value="one time"
                                required
                              />
                              <label htmlFor="money-basis1">
                                Just this time
                              </label>
                              <input
                                className="is-checkradio"
                                id="money-basis2"
                                type="radio"
                                name="donation_type"
                                value="weekly"
                              />
                              <label htmlFor="money-basis2">Every week</label>
                              <input
                                className="is-checkradio"
                                id="money-basis3"
                                type="radio"
                                name="donation_type"
                                value="monthly"
                              />
                              <label htmlFor="money-basis3">Every month</label>
                              <input
                                className="is-checkradio"
                                id="money-basis4"
                                type="radio"
                                name="donation_type"
                                value="annually"
                              />
                              <label htmlFor="money-basis4">Every year</label>
                            </div>
                          </div>
                        </div>
                      </span>
                    </div>
                    <div className="column is-4">
                      <span className="control">
                        <FormCol2 />
                      </span>
                    </div>
                    <div className="column is-4">
                      <span className="control">
                        <FormCol3
                          idPrefix="money"
                          logoFile={logoFile}
                          setLogoFile={setLogoFile}
                        />
                        <div className="field is-grouped is-grouped-right m-b-sm">
                          <button
                            type="submit"
                            id="money-btn"
                            className="button is-success"
                          >
                            Donate
                          </button>
                        </div>
                      </span>
                    </div>
                  </div>
                </span>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Time = ({
  chooserTimeTitle,
  chooserTimeInfo,
  selectedSkills,
  setSelectedSkills,
  skillsList,
  currentUser,
}) => {
  const [fromDate, handleFromDate] = useState(null);
  const [toDate, handleToDate] = useState(null);
  const [logoFile, setLogoFile] = useState({ name: "" });

  return (
    <>
      <div className="m-b-lg md-in-ul">
        <HtmlString2Component str={chooserTimeInfo} />
      </div>
      <div className="columns">
        <div className="column">
          <div className="panel">
            <div className="panel-heading" id="spread">
              Submit {chooserTimeTitle.toLowerCase()}
            </div>

            <div className="panel-block">
              <Success idPrefix="time" />
              <form
                id="time-form"
                action=""
                className="control"
                onSubmit={submitForm({
                  idPrefix: "time",
                  currentUser,
                  logoFile,
                })}
              >
                <span className="control">
                  <div className="columns">
                    <div className="column is-4">
                      <span className="control">
                        <div className="field">
                          <label htmlFor="" className="label">
                            Basis
                            <span className="has-text-danger"> *</span>
                          </label>
                          <div className="control">
                            <div className="field">
                              <input
                                className="is-checkradio"
                                id="time-basis1"
                                type="radio"
                                name="time-basis"
                                value="One-off"
                                required
                              />
                              <label htmlFor="time-basis1">One-off</label>
                              <input
                                className="is-checkradio"
                                id="time-basis2"
                                type="radio"
                                name="time-basis"
                                value="On-going"
                              />
                              <label htmlFor="time-basis2">On-going</label>
                            </div>
                          </div>
                        </div>
                        <div className="field">
                          <ThemeProvider theme={occasioMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DayjsUtils}>
                              <div className="columns">
                                <div className="column p-b-sm">
                                  <label htmlFor="" className="label">
                                    From
                                    <span className="has-text-danger"> *</span>
                                  </label>
                                  <div className="control">
                                    <div className="field">
                                      <DatePicker
                                        name="time-from"
                                        value={fromDate}
                                        onChange={handleFromDate}
                                        format="DD-M-YYYY"
                                        disablePast
                                        fullWidth
                                        inputVariant="outlined"
                                        autoOk
                                        placeholder="Choose a start date"
                                        okLabel=""
                                        cancelLabel=""
                                        required
                                        inputProps={{ readOnly: false }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="column p-b-md">
                                  <label htmlFor="" className="label">
                                    To
                                    <span className="has-text-danger"> *</span>
                                  </label>
                                  <div className="control">
                                    <div className="field">
                                      <DatePicker
                                        name="time-to"
                                        minDate={fromDate || new Date()}
                                        value={toDate}
                                        onChange={handleToDate}
                                        format="DD-M-YYYY"
                                        disablePast
                                        fullWidth
                                        inputVariant="outlined"
                                        autoOk
                                        placeholder="Choose an end date"
                                        okLabel=""
                                        cancelLabel=""
                                        required
                                        inputProps={{ readOnly: false }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </MuiPickersUtilsProvider>
                          </ThemeProvider>
                        </div>
                        <div className="field">
                          <label htmlFor="" className="label">
                            Time available
                            <span className="has-text-danger"> *</span>
                          </label>
                          <div className="control is-flex flex-evenly">
                            <input
                              id="time-available"
                              name="time-available"
                              type="number"
                              // placeholder="e.g. jonhdoe@gmail.com"
                              className="input"
                              min="1"
                              required
                              style={{
                                minWidth: "50px",
                              }}
                            />{" "}
                            hours per
                            <div
                              className="select is-fullwidth"
                              style={{
                                minWidth: "100px",
                              }}
                            >
                              <select
                                name="time-period"
                                required
                                onChange={event => {
                                  switch (event.target.value) {
                                    case "day":
                                      document.getElementById(
                                        "time-available",
                                      ).max = 24;
                                      break;
                                    case "week":
                                      document.getElementById(
                                        "time-available",
                                      ).max = 24 * 7;
                                      break;
                                    case "month":
                                      document.getElementById(
                                        "time-available",
                                      ).max = 24 * 7 * 31;
                                      break;
                                    default:
                                      document
                                        .getElementById("time-available")
                                        .removeAttribute("max");
                                      break;
                                  }
                                }}
                              >
                                <option value="">select</option>
                                <option>day</option>
                                <option>week</option>
                                <option>month</option>
                              </select>
                            </div>
                          </div>
                          <p className="help is-hidden-mobile">
                            <i>hours per person</i>
                          </p>
                        </div>
                        <div className="field">
                          <label htmlFor="" className="label">
                            Number of people
                            <span className="has-text-danger"> *</span>
                          </label>
                          <div className="control">
                            <input
                              name="time-people"
                              type="number"
                              min="1"
                              // placeholder="e.g. jonhdoe@gmail.com"
                              className="input"
                              required
                            />
                          </div>
                        </div>
                        <div className="field">
                          <label htmlFor="" className="label">
                            Skills
                            <span className="has-text-danger"> *</span>
                          </label>
                          <div className="control">
                            <Select
                              isClearable={false}
                              isSearchable
                              isMulti
                              name="time-skills"
                              options={
                                selectedSkills &&
                                Object.keys(selectedSkills).length >= 5
                                  ? selectedSkills
                                  : skillsList
                              }
                              onChange={values => {
                                setSelectedSkills(values);
                              }}
                              value={selectedSkills}
                              noOptionsMessage={() =>
                                "You can't choose more than 5 skills"
                              }
                            />
                            <MakeRequired required={!selectedSkills} />
                          </div>
                        </div>
                      </span>
                    </div>
                    <div className="column is-4">
                      <span className="control">
                        <FormCol2 />
                      </span>
                    </div>
                    <div className="column is-4">
                      <span className="control">
                        <FormCol3
                          idPrefix="time"
                          logoFile={logoFile}
                          setLogoFile={setLogoFile}
                        />
                        <div className="field is-grouped is-grouped-right m-b-sm">
                          <button
                            type="submit"
                            id="time-btn"
                            className="button is-success"
                          >
                            Send
                          </button>
                        </div>
                      </span>
                    </div>
                  </div>
                </span>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Courses = ({ chooserCoursesTitle, chooserCoursesInfo, currentUser }) => {
  const [file, setFile] = useState({ name: "" });
  const [logoFile, setLogoFile] = useState({ name: "" });

  return (
    <>
      <div className="m-b-lg md-in-ul">
        <HtmlString2Component str={chooserCoursesInfo} />
      </div>
      <div className="columns">
        <div className="column">
          <div className="panel">
            <div className="panel-heading" id="spread">
              Submit {chooserCoursesTitle.toLowerCase()}
            </div>

            <div className="panel-block">
              <Success idPrefix="courses" />
              <form
                id="courses-form"
                action=""
                className="control"
                onSubmit={submitForm({
                  idPrefix: "courses",
                  currentUser,
                  file,
                  logoFile,
                })}
              >
                <span className="control">
                  <div className="columns">
                    <div className="column is-4">
                      <span className="control">
                        <div className="field">
                          <label htmlFor="" className="label">
                            Course description
                            <span className="has-text-danger"> *</span>
                          </label>
                          <div className="control">
                            <textarea
                              style={{
                                minHeight: "196px",
                              }}
                              className="textarea"
                              name="details"
                              placeholder="Describe courses and scholarships being donated"
                              required
                              maxLength={500}
                              onChange={event => {
                                document.getElementById(
                                  "courses-details",
                                ).innerHTML = `${500 -
                                  event.target.value.length} characters left`;
                              }}
                            />
                            <p
                              className="help is-italic m-b-none"
                              id="courses-details"
                            >
                              500 characters left
                            </p>
                          </div>
                        </div>
                        <div className="field">
                          <label htmlFor="" className="label">
                            Syllabus
                            <span className="has-text-danger"> *</span>
                          </label>
                          <div className="control">
                            <div className="file has-name is-fullwidth is-success">
                              <label className="file-label">
                                <input
                                  className="file-input"
                                  type="file"
                                  // accept=".pdf"
                                  name="file"
                                  onChange={handleFileSelect(setFile)}
                                />
                                <span className="file-cta">
                                  <span className="file-icon">
                                    <i className="fas fa-upload" />
                                  </span>
                                  <span className="file-label">
                                    Choose a file
                                  </span>
                                </span>
                                <span className="file-name">{file.name}</span>
                              </label>
                            </div>
                            <MakeRequired required={!file.name} />
                            <p className="help is-italic">
                              Maximum size is 5 Mb. We currently only accept one
                              file at a time. Please create a *. zip file if
                              multiple files must be uploaded.
                            </p>
                          </div>
                        </div>
                      </span>
                    </div>
                    <div className="column is-4">
                      <span className="control">
                        <FormCol2 />
                      </span>
                    </div>
                    <div className="column is-4">
                      <span className="control">
                        <FormCol3
                          idPrefix="courses"
                          logoFile={logoFile}
                          setLogoFile={setLogoFile}
                        />
                        <div className="field is-grouped is-grouped-right m-b-sm">
                          <button
                            type="submit"
                            id="courses-btn"
                            className="button is-success"
                          >
                            Send
                          </button>
                        </div>
                      </span>
                    </div>
                  </div>
                </span>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Equipment = ({
  chooserEquipmentTitle,
  chooserEquipmentInfo,
  currentUser,
}) => {
  const [file, setFile] = useState({ name: "" });
  const [logoFile, setLogoFile] = useState({ name: "" });

  return (
    <>
      <div className="m-b-lg md-in-ul">
        <HtmlString2Component str={chooserEquipmentInfo} />
      </div>
      <div className="columns">
        <div className="column">
          <div className="panel">
            <div className="panel-heading" id="spread">
              Submit {chooserEquipmentTitle.toLowerCase()}
            </div>

            <div className="panel-block">
              <Success idPrefix="equipment" />
              <form
                id="equipment-form"
                action=""
                className="control"
                onSubmit={submitForm({
                  idPrefix: "equipment",
                  currentUser,
                  file,
                  logoFile,
                })}
              >
                <span className="control">
                  <div className="columns">
                    <div className="column is-4">
                      <span className="control">
                        <div className="field">
                          <label htmlFor="" className="label">
                            Equipment description
                            <span className="has-text-danger"> *</span>
                          </label>
                          <div className="control">
                            <textarea
                              style={{
                                minHeight: "196px",
                              }}
                              className="textarea"
                              name="details"
                              placeholder="Describe equipment being donated"
                              required
                              maxLength={500}
                              onChange={event => {
                                document.getElementById(
                                  "equipment-details",
                                ).innerHTML = `${500 -
                                  event.target.value.length} characters left`;
                              }}
                            />
                            <p
                              className="help is-italic m-b-none"
                              id="equipment-details"
                            >
                              500 characters left
                            </p>
                          </div>
                        </div>
                        <div className="field">
                          <label htmlFor="" className="label">
                            Pictures and specifications
                            <span className="has-text-danger"> *</span>
                          </label>
                          <div className="control">
                            <div className="file has-name is-fullwidth is-success">
                              <label className="file-label">
                                <input
                                  className="file-input"
                                  type="file"
                                  // accept=".pdf"
                                  name="file"
                                  onChange={handleFileSelect(setFile)}
                                />
                                <span className="file-cta">
                                  <span className="file-icon">
                                    <i className="fas fa-upload" />
                                  </span>
                                  <span className="file-label">
                                    Choose a file
                                  </span>
                                </span>
                                <span className="file-name">{file.name}</span>
                              </label>
                            </div>
                            <MakeRequired required={!file.name} />
                            <p className="help is-italic">
                              Maximum size is 5 Mb. We currently only accept one
                              file at a time. Please create a *. zip file if
                              multiple files must be uploaded.
                            </p>
                          </div>
                        </div>
                      </span>
                    </div>
                    <div className="column is-4">
                      <span className="control">
                        <FormCol2 />
                      </span>
                    </div>
                    <div className="column is-4">
                      <span className="control">
                        <FormCol3
                          idPrefix="equipment"
                          logoFile={logoFile}
                          setLogoFile={setLogoFile}
                        />
                        <div className="field is-grouped is-grouped-right m-b-sm">
                          <button
                            type="submit"
                            id="equipment-btn"
                            className="button is-success"
                          >
                            Send
                          </button>
                        </div>
                      </span>
                    </div>
                  </div>
                </span>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const DonatePageTemplate = ({
  currentUser,
  title,
  subTitle,
  image,
  aboutTitle,
  aboutStep1Title,
  aboutStep1Descr,
  aboutStep2Title,
  aboutStep2Descr,
  aboutStep3Title,
  aboutStep3Descr,
  chooserTitle,
  chooserMoneyTitle,
  chooserMoneyImage,
  chooserMoneyDesc,
  chooserMoneyInfo,
  chooserTimeTitle,
  chooserTimeImage,
  chooserTimeDesc,
  chooserTimeInfo,
  chooserCoursesTitle,
  chooserCoursesImage,
  chooserCoursesDesc,
  chooserCoursesInfo,
  chooserEquipmentTitle,
  chooserEquipmentImage,
  chooserEquipmentDesc,
  chooserEquipmentInfo,
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const tabsRef = useRef();
  const [donationAmount, setDonationAmount] = useState();
  const skillsList = getSkillsList();
  const [selectedSkills, setSelectedSkills] = useState();
  useScript("https://js.stripe.com/v3/");

  useEffect(() => {
    if (currentUser) {
      const userRef = db.collection("users").doc(currentUser.uid);
      // email form
      document.getElementsByName("email").forEach(field => {
        field.value = currentUser.email;
      });
      // phone form
      document.getElementsByName("phone").forEach(field => {
        field.value = currentUser.phoneNumber;
      });
      userRef.get().then(doc => {
        if (doc.exists) {
          // fill up html elements
          [
            ...document.querySelectorAll("input[type=text], input[type=url]"),
          ].forEach(element => {
            const dbVal = doc.data()[element.name];
            if (dbVal !== undefined) {
              element.value = dbVal;
            }
          });
        }
      });
    }
  }, [currentUser]);

  return (
    <div className="content">
      <div
        className="full-width-image-container img-vcentered m-t-none m-b-none"
        style={{
          backgroundImage: `url(${
            image.childImageSharp ? image.childImageSharp.fluid.src : image
          })`,
          justifyContent: "flex-end",
          alignItems: "flex-end",
          flexDirection: "row",
        }}
      >
        <span
          className="container"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            flexGrow: "1",
          }}
        >
          <h2
            className="has-text-weight-medium is-size-1 has-background-primary-half-opaque m-b-md"
            style={{
              color: "white",
              padding: "1rem",
            }}
          >
            {title}
          </h2>
          <h3
            className="has-text-weight-medium is-size-2 has-background-primary-half-opaque m-t-none"
            style={{
              color: "white",
              padding: "1rem",
              marginBottom: "3.5rem ",
            }}
          >
            {subTitle}
          </h3>
        </span>
      </div>
      <section className="section section--gradient has-background-grey-even-lighter">
        <div className="container">
          <h3 className="has-text-weight-medium is-size-2 has-text-centered">
            {aboutTitle}
          </h3>
          <div className="columns is-variable-desktop-9">
            <div className="column is-4">
              <div className="panel with-marker">
                <div className="panel-marker on-top-top">1</div>
                <p className="panel-heading-curved has-background-primary has-text-white-ter m-b-none on-top">
                  {aboutStep1Title}
                </p>
                <div className="panel-block has-background-white on-top">
                  {aboutStep1Descr}
                </div>
              </div>
            </div>
            {/* divider has to be placed between columns in order to not disturb use of :first-child in .is-variable-desktop-9 */}
            <div
              className="column is-10 is-offset-1 is-divider is-hidden-mobile"
              style={{
                position: "absolute",
                top: "calc(50% + 2rem)",
                borderTop: ".1rem solid #8c8c8c",
              }}
            />
            <div className="column is-4">
              <div className="panel with-marker">
                <div className="panel-marker on-top-top">2</div>
                <p className="panel-heading-curved has-background-primary has-text-white-ter m-b-none on-top">
                  {aboutStep2Title}
                </p>
                <div className="panel-block has-background-white on-top">
                  {aboutStep2Descr}
                </div>
              </div>
            </div>
            <div className="column is-4">
              <div className="panel with-marker">
                <div
                  className="panel-marker on-top-top"
                  style={{
                    paddingBottom: "0.2rem",
                  }}
                >
                  3
                </div>
                <p className="panel-heading-curved has-background-primary has-text-white-ter m-b-none on-top">
                  {aboutStep3Title}
                </p>
                <div className="panel-block has-background-white on-top">
                  {aboutStep3Descr}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section section--gradient">
        <div className="container">
          <h3 className="has-text-weight-medium is-size-2 has-text-centered">
            {chooserTitle}
          </h3>
          <div className="columns is-multiline">
            {[
              {
                chooserBlockTitle: chooserMoneyTitle,
                chooserBlockDescr: chooserMoneyDesc,
                chooserBlockImage: chooserMoneyImage,
              },
              {
                chooserBlockTitle: chooserTimeTitle,
                chooserBlockDescr: chooserTimeDesc,
                chooserBlockImage: chooserTimeImage,
              },
              {
                chooserBlockTitle: chooserCoursesTitle,
                chooserBlockDescr: chooserCoursesDesc,
                chooserBlockImage: chooserCoursesImage,
              },
              {
                chooserBlockTitle: chooserEquipmentTitle,
                chooserBlockDescr: chooserEquipmentDesc,
                chooserBlockImage: chooserEquipmentImage,
              },
            ].map(
              (
                { chooserBlockTitle, chooserBlockDescr, chooserBlockImage },
                index,
              ) => (
                <div
                  className="column is-half-tablet is-one-quarter-desktop"
                  key={chooserBlockTitle}
                >
                  <div className="card">
                    <div className="card-image">
                      <figure className="image is-4by3 m-l-none m-r-none">
                        <img
                          src={
                            chooserBlockImage.childImageSharp
                              ? chooserBlockImage.childImageSharp.fluid.src
                              : chooserBlockImage
                          }
                          alt="Placeholder image"
                          style={{
                            borderRadius: "8px 8px 0 0",
                          }}
                        />
                      </figure>
                    </div>
                    <div className="card-content">
                      <div className="media m-b-md">
                        <div className="media-content">
                          <p className="title is-5">{chooserBlockTitle}</p>
                        </div>
                      </div>

                      <div className="content">
                        {chooserBlockDescr}
                        <br />
                        <Link to={`hall-of-fame#${index}`}>
                          See other people donations
                        </Link>
                        <br />
                        <button
                          type="button"
                          className="button is-primary m-t-md"
                          onClick={() => {
                            setTabIndex(index);
                            window.scrollTo(0, tabsRef.current.offsetTop - 67);
                          }}
                        >
                          Learn More
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ),
            )}
          </div>
        </div>
      </section>
      <section className="section section--gradient p-t-md">
        <div ref={tabsRef} className="container">
          <Tabs
            className="tabs is-centered is-boxed is-toggle is-fullwidth"
            selectedTabClassName="is-active"
            selectedIndex={tabIndex}
            onSelect={index => {
              setTabIndex(index);
            }}
            forceRenderTabPanel
          >
            <TabList className="m-b-lg">
              <Tab>
                <a>
                  <span className="icon is-small">
                    <i className="far fa-money-bill-alt" />
                  </span>
                  <span>Financial</span>
                </a>
              </Tab>
              <Tab>
                <a>
                  <span className="icon is-small">
                    <i className="fas fa-hourglass-half" />
                  </span>
                  <span>Time & Skills</span>
                </a>
              </Tab>
              <Tab>
                <a>
                  <span className="icon is-small">
                    <i className="fas fa-graduation-cap" />
                  </span>
                  <span>Courses & Scholarships</span>
                </a>
              </Tab>
              <Tab>
                <a>
                  <span className="icon is-small">
                    <i className="fas fa-drafting-compass" />
                  </span>
                  <span>Tools & Equipment</span>
                </a>
              </Tab>
            </TabList>
            <TabPanel style={{ display: tabIndex === 0 ? "block" : "none" }}>
              <Money
                chooserMoneyInfo={chooserMoneyInfo}
                donationAmount={donationAmount}
                setDonationAmount={setDonationAmount}
                currentUser={currentUser}
              />
            </TabPanel>
            <TabPanel style={{ display: tabIndex === 1 ? "block" : "none" }}>
              <Time
                chooserTimeTitle={chooserTimeTitle}
                chooserTimeInfo={chooserTimeInfo}
                selectedSkills={selectedSkills}
                setSelectedSkills={setSelectedSkills}
                skillsList={skillsList}
                currentUser={currentUser}
              />
            </TabPanel>
            <TabPanel style={{ display: tabIndex === 2 ? "block" : "none" }}>
              <Courses
                chooserCoursesTitle={chooserCoursesTitle}
                chooserCoursesInfo={chooserCoursesInfo}
                currentUser={currentUser}
              />
            </TabPanel>
            <TabPanel style={{ display: tabIndex === 3 ? "block" : "none" }}>
              <Equipment
                chooserEquipmentTitle={chooserEquipmentTitle}
                chooserEquipmentInfo={chooserEquipmentInfo}
                currentUser={currentUser}
              />
            </TabPanel>
          </Tabs>
        </div>
      </section>
    </div>
  );
};

DonatePageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  aboutTitle: PropTypes.string.isRequired,
  aboutStep1Title: PropTypes.string.isRequired,
  aboutStep1Descr: PropTypes.string.isRequired,
  aboutStep2Title: PropTypes.string.isRequired,
  aboutStep2Descr: PropTypes.string.isRequired,
  aboutStep3Title: PropTypes.string.isRequired,
  aboutStep3Descr: PropTypes.string.isRequired,
  chooserTitle: PropTypes.string.isRequired,
  chooserMoneyTitle: PropTypes.string.isRequired,
  chooserMoneyDesc: PropTypes.string.isRequired,
  chooserMoneyInfo: PropTypes.string.isRequired,
  chooserTimeTitle: PropTypes.string.isRequired,
  chooserTimeDesc: PropTypes.string.isRequired,
  chooserTimeInfo: PropTypes.string.isRequired,
  chooserCoursesTitle: PropTypes.string.isRequired,
  chooserCoursesDesc: PropTypes.string.isRequired,
  chooserCoursesInfo: PropTypes.string.isRequired,
  chooserEquipmentTitle: PropTypes.string.isRequired,
  chooserEquipmentDesc: PropTypes.string.isRequired,
  chooserEquipmentInfo: PropTypes.string.isRequired,
};

const DonatePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout>
      <AuthContext.Consumer>
        {({ currentUser }) => (
          <DonatePageTemplate
            currentUser={currentUser}
            contentComponent={HTMLContent}
            title={frontmatter.title}
            subTitle={frontmatter.subTitle}
            image={frontmatter.image}
            aboutTitle={frontmatter.aboutTitle}
            aboutStep1Title={frontmatter.aboutStep1Title}
            aboutStep1Descr={frontmatter.aboutStep1Descr}
            aboutStep2Title={frontmatter.aboutStep2Title}
            aboutStep2Descr={frontmatter.aboutStep2Descr}
            aboutStep3Title={frontmatter.aboutStep3Title}
            aboutStep3Descr={frontmatter.aboutStep3Descr}
            chooserTitle={frontmatter.chooserTitle}
            chooserMoneyTitle={frontmatter.chooserMoneyTitle}
            chooserMoneyImage={frontmatter.chooserMoneyImage}
            chooserMoneyDesc={frontmatter.chooserMoneyDesc}
            chooserMoneyInfo={frontmatter.chooserMoneyInfo}
            chooserTimeTitle={frontmatter.chooserTimeTitle}
            chooserTimeImage={frontmatter.chooserTimeImage}
            chooserTimeDesc={frontmatter.chooserTimeDesc}
            chooserTimeInfo={frontmatter.chooserTimeInfo}
            chooserCoursesTitle={frontmatter.chooserCoursesTitle}
            chooserCoursesImage={frontmatter.chooserCoursesImage}
            chooserCoursesDesc={frontmatter.chooserCoursesDesc}
            chooserCoursesInfo={frontmatter.chooserCoursesInfo}
            chooserEquipmentTitle={frontmatter.chooserEquipmentTitle}
            chooserEquipmentImage={frontmatter.chooserEquipmentImage}
            chooserEquipmentDesc={frontmatter.chooserEquipmentDesc}
            chooserEquipmentInfo={frontmatter.chooserEquipmentInfo}
          />
        )}
      </AuthContext.Consumer>
    </Layout>
  );
};

// DonatePage.propTypes = {
//   data: PropTypes.object.isRequired
// };

export default DonatePage;

export const donatePageQuery = graphql`
  query DonatePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subTitle
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        aboutTitle
        aboutStep1Title
        aboutStep1Descr
        aboutStep2Title
        aboutStep2Descr
        aboutStep3Title
        aboutStep3Descr
        chooserTitle
        chooserMoneyTitle
        chooserMoneyImage {
          childImageSharp {
            fluid(maxWidth: 512, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        chooserMoneyDesc
        chooserMoneyInfo
        chooserTimeTitle
        chooserTimeImage {
          childImageSharp {
            fluid(maxWidth: 512, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        chooserTimeDesc
        chooserTimeInfo
        chooserCoursesTitle
        chooserCoursesImage {
          childImageSharp {
            fluid(maxWidth: 512, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        chooserCoursesDesc
        chooserCoursesInfo
        chooserEquipmentTitle
        chooserEquipmentImage {
          childImageSharp {
            fluid(maxWidth: 512, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        chooserEquipmentDesc
        chooserEquipmentInfo
      }
    }
  }
`;
